import React from 'react';
import styled from 'styled-components';

const Icon = styled.div`
  background-image: url(${({ icon }) => icon});
  background-size: ${({ size }) => size || '12px'};
  background-repeat: no-repeat;
  background-position: center;
  width: ${({ size }) => size || '12px'};
  height: ${({ size }) => size || '12px'};
`;

const RootContainer = ({ icon, color, size, style = {} }) => {
  if (color) {
    style.color = color;
  }
  if (size) {
    style.fontSize = size;
  }
  return <Icon style={style} color={color} size={size} icon={icon} />;
};

export default RootContainer;
