import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Container = styled.div`
  box-sizing: border-box;
  height: 50px;
  padding: 12px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: inline-block;
  flex: 1;
  cursor: pointer;

  &.primary {
    color: white;
    background-color: #ffad01;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  &.secondary {
    background-color: #ffffff;
    border: 2px solid #515151;
  }

  &.submit {
    margin-top: 30px;
    color: white;
    background-color: #ffad01;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const Label = styled.div`
  color: inherit;
  font-family: Arial;
  font-size: 21px;
  letter-spacing: 0;
  line-height: 24px;
}`;

const Button = ({
  variant,
  disabled,
  submit,
  label,
  children,
  icon,
  onClick,
}) => {
  const classNames = [variant];
  if (submit) {
    classNames.push('submit');
  }

  if (disabled) {
    classNames.push('disabled');
  }

  const click = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <Container className={classNames} onClick={click}>
      <Label>{label || children}</Label>
      <Icon icon={icon} size="24px" />
    </Container>
  );
};
export default Button;
